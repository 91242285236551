<template>
  <div>
    <road-card
      :road="road"
      class="mb-4"
    />
    <road-driver :road="road" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import RoadCard from '@/components/road/RoadCard'
// eslint-disable-next-line import/extensions
import RoadDriver from '@/components/road/RoadDriver'

export default {
  name: 'RoadPanel',
  components: { RoadDriver, RoadCard },
  props: {
    road: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
